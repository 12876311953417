var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('company reports'))+" "),_c('v-spacer'),(_vm.select.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"success","depressed":""},on:{"click":function($event){return _vm.openReport(_vm.select[0].id)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("show_company"))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,false,199118896)},[_c('span',[_vm._v(_vm._s(_vm.$t("show_company")))])]):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"show-select":"","single-select":"","loading":_vm.loading,"dense":"","headers":_vm.headers.companyReport,"items":_vm.reports},scopedSlots:_vm._u([{key:"item.Stampduty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTotalFees(item.feesInfo, "Stamp duty"))+" ")]}},{key:"item.Supervisionfees",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTotalFees(item.feesInfo, "Supervision fees"))+" ")]}},{key:"item.Policyfees",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTotalFees(item.feesInfo, "Policy fees"))+" ")]}}]),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1)],1),(_vm.showReport.show)?_c('show-report',{attrs:{"id":_vm.showReport.id},on:{"close":function($event){_vm.showReport.show = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }